import YouTube from 'react-youtube';
// material
import { styled } from '@mui/material/styles';
import { 
  Container, 
  Typography, 
  Grid, 
  Button, 
  Link, 
  Box, 
  List, 
  ListItem, 
  ListItemText,
  ListItemIcon,
  useMediaQuery
} from '@mui/material';
import { motion } from 'framer-motion';
import { varFadeInLeft, varFadeInRight } from '../animate';

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  marginBottom: 64,
  paddingTop: 42,
  paddingBottom: 42,
  background:'linear-gradient(90deg, rgba(115,171,254,0.88) 30%, rgba(110,164,253,1) 47%)',
  width: '100%',  // Ensure RootStyle takes full width
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const TextBox = styled(Box)(({ theme }) => ({
  zIndex: 1,
  position: 'absolute',
  top: { xs: '50%', sm: '50%' },
  left: '24%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'left',
  color: '#0176BC',
  [theme.breakpoints.down('md')]: {
    position: 'relative',
    top: 'auto',
    left: 'auto',
    transform: 'none',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
}));

const ImageBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  display: 'block',
  objectFit: 'cover', // Ensure the image covers the container
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const Text = [
  'MEET THOUSANDS OF CLIENTS', 
  'EARN EXTRA INCOME', 
  'FLEXIBLE HOURS', 
  'LOCATION FLEXIBILITY'
]

export default function HomeFeature() {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  return (
    <RootStyle>
      <motion.div variants={varFadeInLeft} style={{ marginTop: 0, width: '100%' }}>
        <Grid container spacing={5} >
          <Grid item xs={12} sm={12} md={6} sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <Box sx={{maxWidth:'65%'}}>
              <Typography variant={isMobile ? 'h4' : 'h2'} sx={{mb: isMobile ? '5vh' : '10vh'}}>
                BE A JOBSTER NOW USE STARJOBS APP
              </Typography>
              <List>
                {Text.map((item, index) =>(
                  <ListItem key={index}>
                    <Box sx={{ minWidth: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <img src="/static/listIcon.png" alt="icon" style={{ width: isMobile ? 32 : 52, height: isMobile ? 32 : 52 }} />
                    </Box>
                    <ListItemText >
                      <Typography variant={isMobile ? 'h5' : 'h3'} sx={{ color: 'white' }}>
                        {item}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ImageBox
              component="img"
              src="/static/devices.png"
            />
          </Grid>
        </Grid>
      </motion.div>
    </RootStyle>
  );
}
