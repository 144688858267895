import YouTube from 'react-youtube';
// material
import { styled } from '@mui/material/styles';
import { Container, Typography, Grid, Button, Link, Box } from '@mui/material';
import { motion } from 'framer-motion';
import { varFadeInLeft, varFadeInRight } from '../animate';

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  marginBottom: '10vh',
  paddingTop: 42,
  paddingBottom: 42,
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    marginBottom: '10vh'
  },

}));

const ImageBox = styled(Box)(({ theme }) => ({
  width: '50%',
  height: 'auto',
  display: 'block',
  objectFit: 'cover', // Ensure the image covers the container
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));


const MOCK_CAROUSELS = [
  {
    id: 1,
    image: '/static/1.png',
  },
  {
    id: 2,
    image: '/static/2.png',
  },
  {
    id: 3,
    image: '/static/3.png'
  },
  {
    id: 4,
    image: '/static/4.png',
  },
  {
    id: 5,
    image: '/static/5.png',
  },
  {
    id: 6,
    image: '/static/6.png',
  },
  {
    id: 7,
    image: '/static/7.png',
  },
  {
    id: 8,
    image: '/static/8.png',
  },
  {
    id: 9,
    image: '/static/9.png',
  },
  {
    id: 10,
    image: '/static/10.png',
  },
  {
    id: 11,
    image: '/static/11.png',
  },
  {
    id: 12,
    image: '/static/12.png',
  },
  {
    id: 13,
    image: '/static/13.png',
  },
  {
    id: 14,
    image: '/static/14.png',
  },
  {
    id: 15,
    image: '/static/15.png',
  }
];


export default function HomeCompany() {
  return (
    <RootStyle>
      <Box sx={{ width: '90%', display: 'flex',flexDirection:'column', justifyContent: 'center', alignItems:'center' }}>
      <Typography variant='h2' sx={{ textAlign: 'center', marginBottom: 4, color:'#0160bf'}}>Our Client Partners</Typography>
        <Grid container spacing={5} sx={{ width: '100%' }}>
          {MOCK_CAROUSELS.map((item) => (
            <Grid key={item.id} item xs={4} md={2.4} sx={{display:'flex',justifyContent:'center', alignItems:'center'}}>
              <ImageBox
                component="img"
                src={item.image}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </RootStyle>
  );
}
