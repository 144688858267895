const menuConfig = [
    {
      title: 'Home',
      path: 'homeSection'
    },
    {
      title: 'About Us',
      path: 'aboutSection'
    },
    {
      title: 'Sign Up',
      path: 'https://app.starjobs.com.ph/sign-up'
    },
    {
      title: 'Login',
      path: 'https://app.starjobs.com.ph/login'
    }
  ];
  
  export default menuConfig;