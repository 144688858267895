import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Grid, Box, Button, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { varFadeInLeft } from '../animate';

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  marginBottom: 64,
  paddingTop: 42,
  paddingBottom: 42,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#0061BF',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, calories) {
  return { name, calories };
}

const rows = [
  createData('2500 - 3000', 'Php 35,000.00'),
  createData('2001 - 2499', 'Php 30,000.00'),
  createData('1500 - 2000', 'Php 25,000.00'),
  createData('1001 - 1499', 'Php 20,000.00'),
  createData('501 - 1000', 'Php 17,500.00'),
  createData('301 - 500', 'Php 15,000.00'),
  createData('201 - 300', 'Php 12,500.00'),
  createData('101 - 200', 'Php 11,500.00'),
  createData('51 - 100', 'Php 7,500.00'),
  createData('21 - 50', 'Php 5,000.00'),
  createData('11 - 20', 'Php 2,500.00'),
  createData('6 - 10', 'Php 1,500.00'),
  createData('0 - 5', 'Php 1,000.00'),
];

export default function HomeSubscription() {
  return (
    <RootStyle>
      <Box sx={{ width:{xs:'95%'}, margin: 'auto', display: 'flex', justifyContent: 'center' }}>
        <Grid container spacing={5} sx={{ width: '100%', alignItems: 'center', justifyContent: 'center' }}>
          <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection:'column',justifyContent: 'center' }}>
            <TableContainer component={Paper} sx={{height:{xs:'400px',md:'600px'}, overflow:'auto'}}>
              <Table sx={{ minWidth: 300 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Number of Users</StyledTableCell>
                    <StyledTableCell align="right">Price</StyledTableCell>
                    <StyledTableCell align="right"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="right">{row.calories}</StyledTableCell>
                      <StyledTableCell align="right">
                        <Button
                          variant="contained"
                          size="small"
                          sx={{ backgroundColor: '#0061BF', borderRadius: '20px' }}
                          onClick={() => { window.alert('Coming soon'); }}
                        >
                          Subscribe
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{textAlign:'center'}}>
            <Typography variant='h6' marginTop={5}> 15-day free trial | No credit card required | Cancel anytime </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
            <motion.div variants={varFadeInLeft} style={{ marginTop: 0 }}>
              <Box component="img" src="/static/bookNowDetails.png" sx={{ width: '100%', height: 'auto' }} />
            </motion.div>
          </Grid>
        </Grid>
      </Box>
    </RootStyle>
  );
}
