// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// components
// import ScrollToTop from './components/ScrollToTop';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <>
      <ThemeConfig>
            {/* <ScrollToTop /> */}
            <Router />
      </ThemeConfig>
    </>
  );
}
