import React from 'react'
import { Link, Element } from 'react-scroll';
import { Typography, Box } from '@mui/material'
import { styled } from '@mui/material/styles';

import Page from '../components/Page';

import Homebanner from '../components/home/Homebanner';
import HomeServices from '../components/home/HomeServices';
import HomeCompany from '../components/home/HomeCompany';
import HomeFeature from '../components/home/HomeFeature';
import HomeAboutContent from '../components/home/HomeAboutContent';
import HomeSubscription from '../components/home/HomeSubscription';
import HomeContactForm from '../components/home/HomeForm';
import HomeRequest from '../components/home/HomeRequest';
const RootStyle = styled(Page)({
  height: '100%'
});

const ContentStyle = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: theme.palette.background.default
}));

export default function LandingPage() {
  return (
    <RootStyle title="Star Jobs" id="move_top">
    <Element name='homeSection' className='section'>
      <Homebanner/>
    </Element>
    <ContentStyle>
    <Element name="aboutSection" className='section'>
        <HomeAboutContent/>
      </Element>
      <Element> 
        <HomeServices/> 
      </Element>
      <Element name='formSection' className='section'>
        <HomeContactForm/>
      </Element>
      <Element name='pricingSection' className='section'>
        <HomeFeature/>
      </Element>
      <Element>
        <HomeCompany/>
      </Element>
    </ContentStyle>
  </RootStyle>
  )
}
