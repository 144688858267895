import { Link, Element } from 'react-scroll';
// material
import { styled } from '@mui/material/styles';
import { Container, Typography, Grid, Button } from '@mui/material';
import { Icon } from '@iconify/react';

//
import { motion } from 'framer-motion';
import { varFadeInUp } from '../animate';

const RootStyle = styled('div')(({ theme }) => ({
  backgroundColor: '#90CFF4',
  overflow: 'hidden',
  position: 'relative',
  minHeight: 300,
  paddingTop: 42,
  paddingBottom: 42,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export default function HomeRequest() {
  return (
    <RootStyle>
      <Container maxWidth="lg">
        <Grid container spacing={5} alignItems="center" justifyContent="space-between">
          <Grid item xs={12} md={7}>
            <motion.div variants={varFadeInUp} style={{ marginTop: 0 }}>
              <Typography
                variant="h2"
                sx={{
                  color: 'common.black',
                  mt: { xs: 5, sm: 0, md: 0 }
                }}
                component="h2"
              >
                Increase Productivity with Sparkling Time-Keeping System
              </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={4}>
            <motion.div variants={varFadeInUp} style={{ marginTop: 0 }}>
            <Link
                   sx={{
                    '&:hover': {
                      display: 'block',
                      width: '100%',
                      textDecoration: 'none'
                    }
                  }}
                  rel="noreferrer"
                  to='formSection'
                  smooth={true}
                  duration={500}
                >
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    width: '100%',
                    fontSize: 18,
                    borderRadius: '30px',
                    color: 'common.black',
                    backgroundColor: 'common.white',
                    boxShadow: '1px solid #000',
                    '&:hover': {
                      backgroundColor: 'common.white',
                      color: 'blue.light',
                    }
                  }}
                  startIcon={
                    <Icon icon="ion:paper-plane-outline" width="1.2rem" height="1.2rem" style={{ color: 'common.black' }} />
                  }
                >
                  Request Free Trial
                </Button>
                </Link>
            </motion.div>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
