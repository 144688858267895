import { lazy } from 'react';
import { Navigate, useRoutes,} from 'react-router-dom';
// layouts
import MainLayout from './layouts';
import LandingPage from './pages/LandingPage';
// components

// ----------------------------------------------------------------------



export default function Router() {
  return useRoutes([
    // Main Routes
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '', element: <LandingPage /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
